import { ActionIcon, Box, Group, Container, Menu, Paper } from "@mantine/core";
import React from "react";
import IMAGES from "../../image";
import { useSignOut } from "react-auth-kit";
import { IconLogout, IconSettings } from "@tabler/icons-react";
import classes from "./header.module.css";
const HeaderField = () => {

  const signOut = useSignOut();
  return (
    <Paper className={classes.mainPaper}>
      <Container h="8vh" className={classes.header} miw={"100%"}>
        <Box className={classes.flexContainer}>
          <Box className={classes.containerLeft}>
            <Group>
              <img
                className={classes.logo}
                src={IMAGES.logoWhite}
                alt="img not found"
              />
            </Group>
          </Box>
          <Box className={classes.containerRight}>
            <Menu shadow="xl" offset={3} width={150}>
              <Menu.Target>
                <ActionIcon variant="transparent" mt="0.5rem">
                  <IconSettings size="2rem" color="white" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown className={classes.profileMenu}>
                <Box className={classes.profileList} onClick={() => signOut()}>
                  <Menu.Item className={classes.profileListItem}>
                    <IconLogout size={25} />
                    Sign Out
                  </Menu.Item>
                </Box>
              </Menu.Dropdown>
            </Menu>
          </Box>
        </Box>
      </Container>
    </Paper>
  );
};

export default HeaderField;
