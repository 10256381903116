import { Loader } from "@mantine/core";

const ThemeLoader = () => {

  return (
    <Loader
      variant="oval"
      size="lg"
      color="pink"
      pos={"absolute"}
      top={"50%"}
      bottom={"50%"}
    />
  );
};


export default ThemeLoader;
